import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '@datasus/core';

import { TipoDominioItemModel } from 'src/app/rotina/tabela/produto/models/tipo-dominio-item-model';

@Injectable({
  providedIn: 'root'
})
export class TipoDemandaService extends BaseService {

  obterDominio(params?: number): Observable<TipoDominioItemModel[]> {
    if (params) {
      return this.baseGet<TipoDominioItemModel[]>(`/tipo-demanda/dominio?tipo-modelo=${params}`);
    }
    return this.baseGet<TipoDominioItemModel[]>(`/tipo-demanda/dominio`);
  }

}
