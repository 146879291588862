import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableRequestModel, PageableResponseModel } from '@datasus/core';

import { ProdutoModel } from '../models/produto.model';
import { ProdutoFilterModel } from '../models/produto-filter.model';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends BaseService {


  obterPorId(id: number): Observable<ProdutoModel> {
    return this.baseGet<ProdutoModel>(`/produto/${id}`);
  }

  obterTodos(): Observable<ProdutoModel[]> {
    return this.baseGet<ProdutoModel[]>('/produto');
  }

  obterTodosPaginado(body: PageableRequestModel<ProdutoFilterModel>): Observable<PageableResponseModel<ProdutoModel>> {
    return this.basePost<PageableResponseModel<ProdutoModel>>('/produto/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/produto/dominio');
  }

  obterAutocompleteDominio(termo: string, idCategoria?: string): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>(`/produto/autocomplete?termo=${termo}&categoria=${idCategoria}`);
  }

  obterAutocompleteDominioPorTermo(termo: string): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>(`/produto/autocomplete?termo=${termo}`);
  }

  incluir(body: ProdutoModel): Observable<ProdutoModel> {
    return this.basePost<ProdutoModel>('/produto', body);
  }

  alterar(body: ProdutoModel, id: number): Observable<ProdutoModel> {
    return this.basePut<ProdutoModel>(`/produto/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/produto/${id}`);
  }

}
