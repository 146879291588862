import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableRequestModel, PageableResponseModel } from '@datasus/core';

import { PerfilFilterModel } from '../models/perfil-filter.model';
import { PerfilModel } from '../models/perfil.model';

@Injectable({
  providedIn: 'root'
})
export class PerfilService extends BaseService {

  obterPorId(id: number): Observable<PerfilModel> {
    return this.baseGet<PerfilModel>(`/perfil/${id}`);
  }

  obterTodos(): Observable<PerfilModel[]> {
    return this.baseGet<PerfilModel[]>('/perfil');
  }

  obterTodosPaginado(body: PageableRequestModel<PerfilFilterModel>): Observable<PageableResponseModel<PerfilModel>> {
    return this.basePost<PageableResponseModel<PerfilModel>>('/perfil/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/perfil/dominio');
  }

  incluir(body: PerfilModel): Observable<PerfilModel> {
    return this.basePost<PerfilModel>('/perfil', body);
  }

  alterar(body: PerfilModel, id: number): Observable<PerfilModel> {
    return this.basePut<PerfilModel>(`/perfil/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/perfil/${id}`);
  }

  obterDominioPerfilAdm(id: number): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>(`/perfil/administrativo/area/${id}`);
  }

  obterDominioPerfilJudicial(id: number): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>(`/perfil/judicial/area/${id}`);
  }

}
