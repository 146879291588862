import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableRequestModel, PageableResponseModel } from '@datasus/core';

import { TipoAcaoModel } from '../models/tipo-acao.model';
import { TipoAcaoFilterModel } from '../models/tipo-acao-filter.model';

@Injectable()
export class TipoAcaoService extends BaseService {

  obterPorId(id: number): Observable<TipoAcaoModel> {
    return this.baseGet<TipoAcaoModel>(`/tipo-acao/${id}`);
  }

  obterTodos(): Observable<TipoAcaoModel[]> {
    return this.baseGet<TipoAcaoModel[]>('/tipo-acao');
  }

  obterTodosPaginado(body: PageableRequestModel<TipoAcaoFilterModel>): Observable<PageableResponseModel<TipoAcaoModel>> {
    return this.basePost<PageableResponseModel<TipoAcaoModel>>('/tipo-acao/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/tipo-acao/dominio');
  }

  incluir(body: TipoAcaoModel): Observable<TipoAcaoModel> {
    return this.basePost<TipoAcaoModel>('/tipo-acao', body);
  }

  alterar(body: TipoAcaoModel, id: number): Observable<TipoAcaoModel> {
    return this.basePut<TipoAcaoModel>(`/tipo-acao/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/tipo-acao/${id}`);
  }

}
