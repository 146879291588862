import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableRequestModel, PageableResponseModel } from '@datasus/core';

import { UnidadeTransferenciaModel } from '../models/unidade-transferencia.model';
import { UnidadeTransferenciaFilterModel } from '../models/unidade-transferencia-filter.model';

@Injectable({
  providedIn: 'root'
})
export class UnidadeTransferenciaService extends BaseService {

  obterPorId(id: number): Observable<UnidadeTransferenciaModel> {
    return this.baseGet<UnidadeTransferenciaModel>(`/unidade-transferencia/${id}`);
  }

  obterTodos(): Observable<UnidadeTransferenciaModel[]> {
    return this.baseGet<UnidadeTransferenciaModel[]>('/unidade-transferencia');
  }

  obterTodosPaginado(body: PageableRequestModel<UnidadeTransferenciaFilterModel>):
    Observable<PageableResponseModel<UnidadeTransferenciaModel>> {
    return this.basePost<PageableResponseModel<UnidadeTransferenciaModel>>('/unidade-transferencia/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/unidade-transferencia/dominio');
  }

  incluir(body: UnidadeTransferenciaModel): Observable<UnidadeTransferenciaModel> {
    return this.basePost<UnidadeTransferenciaModel>('/unidade-transferencia', body);
  }

  alterar(body: UnidadeTransferenciaModel, id: number): Observable<UnidadeTransferenciaModel> {
    return this.basePut<UnidadeTransferenciaModel>(`/unidade-transferencia/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/unidade-transferencia/${id}`);
  }

}
