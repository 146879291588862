import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';
import { CidadeModel } from '../models/cidade.model';

@Injectable({ providedIn: 'root' })
export class CidadeService extends BaseService {

  obterPorId(id: number): Observable<CidadeModel> {
    return this.baseGet<CidadeModel>(`/cidade/${id}`);
  }

  obterTodos(): Observable<CidadeModel[]> {
    return this.baseGet<CidadeModel[]>('/cidade');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/cidade/dominio');
  }

  obterCidadesPorUf(id: string): Observable<CidadeModel[]> {
    return this.baseGet<CidadeModel[]>(`/cidade/uf/${id}`);
  }

  obterCidadesPorNome(nome: string, uf: string): Observable<CidadeModel[]> {
    return this.baseGet<CidadeModel[]>(`/cidade/nome-cidade/${nome}/uf-cidade/${uf}`);
  }
}
