import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel, PageableRequestModel, PageableResponseModel } from '@datasus/core';

import { NaturezaDespesaModel } from '../models/natureza-despesa.model';
import { NaturezaDespesaFilterModel } from '../models/natureza-despesa-filter.model';

@Injectable()
export class NaturezaDespesaService extends BaseService {

  obterPorId(id: number): Observable<NaturezaDespesaModel> {
    return this.baseGet<NaturezaDespesaModel>(`/natureza-despesa/${id}`);
  }

  obterTodos(): Observable<NaturezaDespesaModel[]> {
    return this.baseGet<NaturezaDespesaModel[]>('/natureza-despesa');
  }

  obterTodosPaginado(body: PageableRequestModel<NaturezaDespesaFilterModel>): Observable<PageableResponseModel<NaturezaDespesaModel>> {
    return this.basePost<PageableResponseModel<NaturezaDespesaModel>>('/natureza-despesa/paginado', body);
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/natureza-despesa/dominio');
  }

  incluir(body: NaturezaDespesaModel): Observable<NaturezaDespesaModel> {
    return this.basePost<NaturezaDespesaModel>('/natureza-despesa', body);
  }

  alterar(body: NaturezaDespesaModel, id: number): Observable<NaturezaDespesaModel> {
    return this.basePut<NaturezaDespesaModel>(`/natureza-despesa/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/natureza-despesa/${id}`);
  }

}
