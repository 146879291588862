import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { PerfilService } from '../services/perfil.service';
import { PerfilModel } from '../models/perfil.model';

@Injectable({
  providedIn: 'root'
})
export class PerfisResolver implements Resolve<PerfilModel[]> {

  constructor(
    private perfilService: PerfilService,
  ) { }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PerfilModel[]> | Promise<PerfilModel[]> | PerfilModel[] {
    return this.perfilService.obterTodos();
  }

}
