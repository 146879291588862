import { AbstractControl } from '@angular/forms';

export class NumberValidators {
  /**
   * Valida somente digitos
   */
  static onlyDigits(control: AbstractControl) {
    const value = control.value;
    const digitsPattern = /^\d+$/g;
    if (value != null && !digitsPattern.test(String(value))) {
      return { onlyDigits: true };
    }
    return null;
  }

}
