import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';

import { UfModel } from '../models/uf.model';

@Injectable({ providedIn: 'root' })
export class UfService extends BaseService {

  obterPorId(id: string): Observable<UfModel> {
    return this.baseGet<UfModel>(`/uf/${id}`);
  }

  obterTodos(): Observable<UfModel[]> {
    return this.baseGet<UfModel[]>('/uf');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/uf/dominio');
  }

}
