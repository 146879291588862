import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';

import { TipoProfissaoModel } from '../models/tipo-profissao.model';

@Injectable({ providedIn: 'root' })
export class TipoProfissaoService extends BaseService {

  obterPorId(id: number): Observable<TipoProfissaoModel> {
    return this.baseGet<TipoProfissaoModel>(`/tipo-profissao/${id}`);
  }

  obterTodos(): Observable<TipoProfissaoModel[]> {
    return this.baseGet<TipoProfissaoModel[]>('/tipo-profissao');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/tipo-profissao/dominio');
  }

}
