import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';

import { AreaModel } from '../models/area.model';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends BaseService {

  obterPorId(id: number): Observable<AreaModel> {
    return this.baseGet<AreaModel>(`/area/${id}`);
  }

  obterTodos(): Observable<AreaModel[]> {
    return this.baseGet<AreaModel[]>('/area');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/area/dominio');
  }

}
