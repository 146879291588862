import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';

import { UnidadeDispensadoraModel } from '../models/unidade-dispensadora.model';

@Injectable({ providedIn: 'root' })
export class UnidadeDispensadoraService extends BaseService {

  obterPorId(id: number): Observable<UnidadeDispensadoraModel> {
    return this.baseGet<UnidadeDispensadoraModel>(`/unidade-dispensadora/${id}`);
  }

  obterTodos(): Observable<UnidadeDispensadoraModel[]> {
    return this.baseGet<UnidadeDispensadoraModel[]>('/unidade-dispensadora');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/unidade-dispensadora/dominio');
  }

  incluir(body: UnidadeDispensadoraModel): Observable<UnidadeDispensadoraModel> {
    return this.basePost<UnidadeDispensadoraModel>('/unidade-dispensadora', body);
  }

  alterar(body: UnidadeDispensadoraModel, id: number): Observable<UnidadeDispensadoraModel> {
    return this.basePut<UnidadeDispensadoraModel>(`/unidade-dispensadora/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/unidade-dispensadora/${id}`);
  }

}
