import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, DominioItemModel } from '@datasus/core';

import { UnidadeOrganizacionalModel } from '../models/unidade-organizacional.model';

@Injectable({ providedIn: 'root' })
export class UnidadeOrganizacionalService extends BaseService {

  obterPorId(id: number): Observable<UnidadeOrganizacionalModel> {
    return this.baseGet<UnidadeOrganizacionalModel>(`/unidade-organizacional/${id}`);
  }

  obterTodos(): Observable<UnidadeOrganizacionalModel[]> {
    return this.baseGet<UnidadeOrganizacionalModel[]>('/unidade-organizacional');
  }

  obterDominio(): Observable<DominioItemModel[]> {
    return this.baseGet<DominioItemModel[]>('/unidade-organizacional/dominio');
  }

  incluir(body: UnidadeOrganizacionalModel): Observable<UnidadeOrganizacionalModel> {
    return this.basePost<UnidadeOrganizacionalModel>('/unidade-organizacional', body);
  }

  alterar(body: UnidadeOrganizacionalModel, id: number): Observable<UnidadeOrganizacionalModel> {
    return this.basePut<UnidadeOrganizacionalModel>(`/unidade-organizacional/${id}`, body);
  }

  excluir(id: number): Observable<void> {
    return this.baseDelete<void>(`/unidade-organizacional/${id}`);
  }

}
